import React, { useMemo } from "react";
import { AnimatedBar, AnimatedText } from "./Bars.js";
import { withParentSize } from "@visx/responsive";
import { scaleBand, scaleLinear } from "@visx/scale";
import { Group } from "@visx/group";
import { hatch } from "ldrs";
import { AxisLeft } from "@visx/axis";
import { Text } from "@visx/text";
import { formatLargeNumber } from "../../utility/numberFormats.js";

hatch.register();
const { colors } = require("../../utility/colors.js");

const BaseHorizontalDivergentBarChart = ({
  chartData,
  comparisonData,
  height,
  width,
  parentHeight,
  parentWidth,
  positiveColor = ["#1057B2"],
  postiveComparisonColor = ["#0C4083"],
  negativeColor = ["#89023E"],
  negativeComparisonColor = ["#3E023E"],
  verticalMargin = 0,
  horizontalMargin = 120,
  labelAreaWidth = 120,
  barPadding = 0.4,
  axisTitle = null,
  negativeLabel = null,
  positiveLabel = null,
  loading = false,
  dataLabelFormatter = null,
  isPercentage = false,
  valueMin = null,
  valueMax = null,
}) => {
  height = height || parentHeight;
  width = width || parentWidth;

  if (!dataLabelFormatter) {
    if (isPercentage) {
      dataLabelFormatter = (d) => `${(d * 100).toFixed(0)}%`;
    } else {
      dataLabelFormatter = formatLargeNumber;
    }
  }

  // Set the margins:
  const yMax = height - verticalMargin;
  const xMax = width - horizontalMargin - labelAreaWidth;

  // Calculate the max across all chart and comparison (if exists) data
  if (comparisonData) {
    const comparisonDataValues = comparisonData.map((d) => Math.abs(d.value));
    const chartDataValues = chartData.map((d) => Math.abs(d.value));
    valueMin = valueMin || 0;
    valueMax =
      valueMax || Math.max(...comparisonDataValues, ...chartDataValues);
  } else {
    valueMin = valueMin || 0;
    valueMax = valueMax || Math.max(...chartData.map((d) => Math.abs(d.value)));
  }

  // Create the x and y scales:
  const xScale = useMemo(
    () =>
      scaleLinear({
        range: [0, xMax],
        domain: [-valueMax, valueMax],
      }),
    [xMax, chartData, comparisonData]
  );

  const yScale = useMemo(
    () =>
      scaleBand({
        range: [0, yMax],
        domain: chartData.map((d) => d.label),
        padding: barPadding,
      }),
    [yMax, chartData, comparisonData]
  );

  const barHeight = comparisonData
    ? yScale.bandwidth() / 2
    : yScale.bandwidth();

  return width < 10 || height < 10 ? null : (
    <>
      {loading && (
        <div className="relative">
          <div
            className={`w-full h-[${height}px] flex items-center justify-center absolute`}
          >
            <l-hatch size="32" color="#334155"></l-hatch>
          </div>
        </div>
      )}{" "}
      <svg width={width} height={height} className="">
        <Group
          top={verticalMargin / 2}
          left={horizontalMargin / 2 + labelAreaWidth}
        >
          {chartData.map((d, i) => {
            const barWidth =
              d.value < 0
                ? xScale(0) - xScale(d.value)
                : xScale(d.value) - xScale(0);
            const barX = d.value < 0 ? xScale(0) - barWidth : xScale(0);
            const barY = yScale(d.label);
            return (
              <React.Fragment key={`bar-${d.label}`}>
                <AnimatedBar
                  x={barX}
                  y={barY}
                  width={barWidth}
                  height={barHeight}
                  fill={d.value < 0 ? negativeColor : positiveColor}
                  direction={d.value < 0 ? "left" : "right"}
                />
                <AnimatedText
                  x={d.value < 0 ? barX - 10 : barX + barWidth + 10}
                  y={barY + barHeight / 2}
                  text={dataLabelFormatter(Math.abs(d.value))}
                  textAnchor={d.value < 0 ? "end" : "start"}
                  fill={colors.mcharcoal["700"]}
                  width={horizontalMargin / 2}
                />
              </React.Fragment>
            );
          })}
          {comparisonData &&
            chartData.map((d, i) => {
              const comparisonDatum = comparisonData.find(
                (cd) => cd.label === d.label
              );
              if (comparisonDatum) {
                const barWidth =
                  comparisonDatum.value < 0
                    ? xScale(0) - xScale(comparisonDatum.value)
                    : xScale(comparisonDatum.value) - xScale(0);
                const barX =
                  comparisonDatum.value < 0 ? xScale(0) - barWidth : xScale(0);
                const barY = yScale(comparisonDatum.label) + barHeight;

                return (
                  <React.Fragment key={`bar-compare-${comparisonDatum.label}`}>
                    <AnimatedBar
                      x={barX}
                      y={barY}
                      width={barWidth}
                      height={barHeight}
                      fill={
                        comparisonDatum.value < 0
                          ? negativeComparisonColor
                          : postiveComparisonColor
                      }
                      direction={comparisonDatum.value < 0 ? "left" : "right"}
                    />
                    <AnimatedText
                      x={
                        comparisonDatum.value < 0
                          ? barX - 10
                          : barX + barWidth + 10
                      }
                      y={barY + barHeight / 2}
                      text={dataLabelFormatter(Math.abs(comparisonDatum.value))}
                      textAnchor={comparisonDatum.value < 0 ? "end" : "start"}
                      fill={colors.mcharcoal["700"]}
                      width={horizontalMargin / 2}
                    />
                  </React.Fragment>
                );
              }
            })}
        </Group>
        <AxisLeft
          top={verticalMargin / 2}
          left={horizontalMargin / 2 + labelAreaWidth + xMax / 2}
          scale={yScale}
          stroke={colors.mcharcoal["700"]}
          strokeWidth={2}
          hideTicks={true}
          tickComponent={(props) => {
            const { x, y, formattedValue, opacity } = props;
            return (
              <Text
                x={x - 5 - xScale(0) - horizontalMargin / 2}
                y={y}
                height={yScale.bandwidth()}
                width={labelAreaWidth}
                fill={colors.mcharcoal["700"]}
                fontSize={14}
                textAnchor="end"
                verticalAnchor="middle"
                className="text-sm"
                opacity={opacity}
              >
                {formattedValue}
              </Text>
            );
          }}
        />
        {axisTitle && (
          <Text
            y={0}
            x={horizontalMargin * 0.5 + labelAreaWidth + xScale(0)}
            width={xScale(0) + horizontalMargin}
            fill={colors.mcharcoal["700"]}
            textAnchor="middle"
            verticalAnchor="start"
            className="text-sm"
          >
            {axisTitle}
          </Text>
        )}
        {positiveLabel && (
          <Text
            y={height - 5}
            x={horizontalMargin * 0.75 + labelAreaWidth + xScale(0) * 1.5}
            width={(xScale(0) + horizontalMargin / 2) * 0.8}
            fill={colors.mcharcoal["700"]}
            textAnchor="middle"
            verticalAnchor="end"
            className="text-xs"
          >
            {positiveLabel}
          </Text>
        )}
        {negativeLabel && (
          <Text
            y={height - 5}
            x={horizontalMargin * 0.25 + labelAreaWidth + xScale(0) * 0.5}
            width={(xScale(0) + horizontalMargin / 2) * 0.8}
            fill={colors.mcharcoal["700"]}
            textAnchor="middle"
            verticalAnchor="end"
            className="text-xs"
          >
            {negativeLabel}
          </Text>
        )}
      </svg>
    </>
  );
};

const HorizontalDivergentBarChart = withParentSize(
  BaseHorizontalDivergentBarChart
);

export { HorizontalDivergentBarChart };
