import {
  UserGroupIcon,
  CalendarIcon,
  IdentificationIcon,
  ScaleIcon,
  MapIcon,
  HeartIcon,
  CurrencyDollarIcon,
  UsersIcon,
  AcademicCapIcon,
  ClipboardDocumentCheckIcon,
  ChartBarIcon,
  HandThumbUpIcon,
} from "@heroicons/react/24/solid";

export const searchSuggestions = [
  "partisanship in North Carolina by county",
  "abortion support in FL congressional districts",
  "New York",
  "state upper houses in TX",
  "Trump support in Los Angeles County, CA",
  "turnout in WY counties",
  "Shelby County",
];

export const tabColors = {
  metrics: "style-primary",
  filters: "style-secondary",
  places: "style-green",
};

export const elections = [
  "General_election_2023_turnout",
  "General_election_2022_turnout",
  "General_election_2021_turnout",
  "General_election_2020_turnout",
  "General_election_2019_turnout",
  "General_election_2018_turnout",
  "General_election_2017_turnout",
  "General_election_2016_turnout",
  "General_election_2015_turnout",
  "General_election_2014_turnout",
  "General_election_2013_turnout",
  "General_election_2012_turnout",
  "Primary_election_2023_turnout",
  "Primary_election_2022_turnout",
  "Primary_election_2021_turnout",
  "Primary_election_2020_turnout",
  "Primary_election_2019_turnout",
  "Primary_election_2018_turnout",
  "Primary_election_2017_turnout",
  "Primary_election_2016_turnout",
  "Primary_election_2015_turnout",
  "Primary_election_2014_turnout",
  "Primary_election_2013_turnout",
  "Primary_election_2012_turnout",
  "Presidential_Primary_2024_turnout",
  "Presidential_Primary_2020_turnout",
  "Presidential_Primary_2016_turnout",
  "Presidential_Primary_2012_turnout",
  "Municipal_election_2023_turnout",
  "Municipal_election_2022_turnout",
  "Municipal_election_2021_turnout",
  "Municipal_election_2020_turnout",
  "Municipal_election_2019_turnout",
  "Municipal_election_2018_turnout",
  "Municipal_election_2016_turnout",
  "Municipal_election_2015_turnout",
  "Municipal_election_2014_turnout",
  "Municipal_election_2013_turnout",
  "Municipal_election_2012_turnout",
];

export const tableParameters = {
  ethnicity: {
    icon: <UserGroupIcon />,
    metrics: [
      "Percent Ethnicity White",
      "Percent Ethnicity Black",
      "Percent Ethnicity Hispanic",
      "Percent Ethnicity Asian",
    ],
    filters: [
      "Ethnicity White",
      "Ethnicity Black",
      "Ethnicity Hispanic",
      "Ethnicity Asian",
    ],
  },
  age: {
    icon: <CalendarIcon />,
    metrics: [
      "Percent Age 18-24",
      "Percent Age 25-34",
      "Percent Age 35-44",
      "Percent Age 45-64",
      "Percent Age 65-84",
      "Percent Age 85+",
    ],
    filters: [
      "Age 18-24",
      "Age 25-34",
      "Age 35-44",
      "Age 45-64",
      "Age 65-84",
      "Age 85+",
    ],
  },
  gender: {
    icon: <IdentificationIcon />,
    metrics: ["Percent Gender Male", "Percent Gender Female"],
    filters: ["Gender Male", "Gender Female"],
  },
  early_voting: {
    icon: <CalendarIcon />,
    metrics: ["Early Vote Total"],
  },
  partisanship: {
    icon: <ScaleIcon />,
    metrics: [
      "Percent Party Democrat",
      "Percent Party Republican",
      "Percent Party Independent",
      "Average Partisan Score",
      "Average Enhanced Ideology Score",
    ],
    filters: ["Party Democrat", "Party Republican", "Party Independent"],
  },
  urbanicity: {
    icon: <MapIcon />,
    metrics: [
      "Percent Urbanicity Urban",
      "Percent Urbanicity Suburban",
      "Percent Urbanicity Rural",
    ],
    filters: ["Urbanicity Urban", "Urbanicity Suburban", "Urbanicity Rural"],
  },
  "marital status": {
    icon: <HeartIcon />,
    metrics: [
      "Percent Marital Status Married",
      "Percent Marital Status Single",
    ],
    filters: ["Marital Status Married", "Marital Status Single"],
  },
  income: {
    icon: <CurrencyDollarIcon />,
    metrics: ["Average Income Rank Score"],
  },
  "children present": {
    icon: <UsersIcon />,
    metrics: ["Average Children Present Score"],
  },
  education: {
    icon: <AcademicCapIcon />,
    metrics: [
      "Average College Graduate Score",
      "Average High School Only Education Score",
    ],
  },
  "vote history": {
    icon: <ClipboardDocumentCheckIcon />,
    metrics: [...elections],
  },
  "turnout scores": {
    icon: <ChartBarIcon />,
    metrics: [
      "Average Non Presidential Primary Turnout Score",
      "Average Presidential Primary Turnout Score",
      "Average Presidential General Turnout Score",
      "Average Midterm General Turnout Score",
      "Average Off Year General Turnout Score",
      "Average Local Voter Score",
    ],
    filters: {
      "presidential general turnout bucket": [
        "0-20",
        "21-40",
        "41-60",
        "61-80",
        "81-100",
      ],
      "midterm general turnout bucket": [
        "0-20",
        "21-40",
        "41-60",
        "61-80",
        "81-100",
      ],
      "presidential primary turnout bucket": [
        "0-20",
        "21-40",
        "41-60",
        "61-80",
        "81-100",
      ],
      "offyear general turnout bucket": [
        "0-20",
        "21-40",
        "41-60",
        "61-80",
        "81-100",
      ],
      "non-presidential primary turnout bucket": [
        "0-20",
        "21-40",
        "41-60",
        "61-80",
        "81-100",
      ],
    },
  },
  scores: {
    icon: <HandThumbUpIcon />,
    metrics: [
      "Average Biden Support Score",
      "Average Trump Support Score",
      "Average Progressive Tax Support Score",
      "Average Gun Control Support Score",
      "Average Gun Owner Score",
      "Average Rank Choice Voting Support Score",
      "Average Campaign Finance Reform Support Score",
      "Average Democracy Protection Score",
      "Average Independent Redistricting Support Score",
      "Average Minimum Wage Support Score",
      "Average Labor Union Support Score",
      "Average Charter School Support Score",
      "Average Big Changes in Education Score",
      "Average Education Voter Score",
      "Average Climate Change Concern Score",
      "Average Marriage Equality Support Score",
      "Average Reproductive Rights Voter Score",
      "Average Reproductive Rights Support Score",
      "Average Veteran Score",
      "Average Frequent Religious Attender Score",
      "Average Activist Engagement Score",
      "Average Grassroots Engagement Score",
      "Average Community Engagement Score",
      "Average Civic Volunteer Score",
      "Average Social Engagement Score",
      "Average Phone Contactability Score",
      "Average Paid Leave Support Score",
      "Average Path to Citizenship Score",
      "Average Third Party Support Score",
      "Average Spanish Speaking Score",
      "Average Downballot Completion Score",
    ],
  },
  elections: {
    icon: <ChartBarIcon />,
    metrics: [...elections],
    filters: [
      "g2023 voted",
      "g2022 voted",
      "g2021 voted",
      "g2020 voted",
      "g2019 voted",
      "g2018 voted",
      "g2017 voted",
      "g2016 voted",
      "g2015 voted",
      "g2014 voted",
      "g2013 voted",
      "g2012 voted",
      "p2023 voted",
      "p2022 voted",
      "p2021 voted",
      "p2020 voted",
      "p2019 voted",
      "p2018 voted",
      "p2017 voted",
      "p2016 voted",
      "p2015 voted",
      "p2014 voted",
      "p2013 voted",
      "p2012 voted",
      "pp2024 voted",
      "pp2020 voted",
      "pp2016 voted",
      "pp2012 voted",
      "g2023 no-vote",
      "g2022 no-vote",
      "g2021 no-vote",
      "g2020 no-vote",
      "g2019 no-vote",
      "g2018 no-vote",
      "g2017 no-vote",
      "g2016 no-vote",
      "g2015 no-vote",
      "g2014 no-vote",
      "g2013 no-vote",
      "g2012 no-vote",
      "p2023 no-vote",
      "p2022 no-vote",
      "p2021 no-vote",
      "p2020 no-vote",
      "p2019 no-vote",
      "p2018 no-vote",
      "p2017 no-vote",
      "p2016 no-vote",
      "p2015 no-vote",
      "p2014 no-vote",
      "p2013 no-vote",
      "p2012 no-vote",
      "pp2024 no-vote",
      "pp2020 no-vote",
      "pp2016 no-vote",
      "pp2012 no-vote",
      "pp2024 party r",
      "pp2024 party d",
      "pp2024 party o",
      "pp2020 party r",
      "pp2020 party d",
      "pp2020 party o",
      "pp2016 party r",
      "pp2016 party d",
      "pp2016 party o",
      "pp2012 party r",
      "pp2012 party d",
      "pp2012 party o",
      "p2024 party r",
      "p2024 party d",
      "p2024 party o",
      "p2023 party r",
      "p2023 party d",
      "p2023 party o",
      "p2022 party r",
      "p2022 party d",
      "p2022 party o",
      "p2021 party r",
      "p2021 party d",
      "p2021 party o",
      "p2020 party r",
      "p2020 party d",
      "p2020 party o",
      "p2019 party r",
      "p2019 party d",
      "p2019 party o",
      "p2018 party r",
      "p2018 party d",
      "p2018 party o",
      "p2017 party r",
      "p2017 party d",
      "p2017 party o",
      "p2016 party r",
      "p2016 party d",
      "p2016 party o",
      "p2015 party r",
      "p2015 party d",
      "p2015 party o",
      "p2014 party r",
      "p2014 party d",
      "p2014 party o",
      "p2013 party r",
      "p2013 party d",
      "p2013 party o",
      "p2012 party r",
      "p2012 party d",
      "p2012 party o",
    ],
  },
};
