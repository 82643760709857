import React, { useRef, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { setSelectedTab } from "../../store/districtPage";
import { useSpring, animated } from "@react-spring/web";

const TabsHeader = () => {
  const dispatch = useDispatch();
  const activeTab = useSelector((state) => state.districtPage.selectedTab);
  const tabRefs = useRef([]);

  const tabs = [
    "Demographics",
    "Turnout Scores",
    "Vote History",
    "Scores",
    "Civic Pulse",
    "Early Vote",
  ];

  const [underlineProps, setUnderline] = useSpring(() => ({
    left: 0,
    width: 0,
  }));

  useEffect(() => {
    const activeTabIndex = tabs.indexOf(activeTab);
    if (activeTabIndex !== -1 && tabRefs.current[activeTabIndex]) {
      const tabElement = tabRefs.current[activeTabIndex];
      setUnderline({
        left: tabElement.offsetLeft,
        width: tabElement.offsetWidth,
      });
    }
  }, [activeTab, tabs]);

  return (
    <div className="tabs col-span-12 flex justify-start pb-2 relative">
      {tabs.map((tab, index) => (
        <button
          key={tab}
          ref={(el) => (tabRefs.current[index] = el)}
          className={`tab text-sm mr-10 p-2 ${
            activeTab === tab ? "text-morange-700 font-semibold" : "font-medium"
          }`}
          onClick={() => dispatch(setSelectedTab(tab))}
        >
          {tab}
        </button>
      ))}
      <animated.div
        className="absolute bottom-0 h-0.5 bg-morange-700"
        style={{
          left: underlineProps.left,
          width: underlineProps.width,
        }}
      />
    </div>
  );
};

export { TabsHeader };
