import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { BarChartCard } from "../../components/layouts/Cards";
import { Grid } from "../../components/layouts/Grid";
import {
  setEarlyVoteVoteType,
  setEarlyVoteComparison,
} from "../../store/districtPage";
import { PopUpPicker } from "../../components/forms/PopUpPicker";
const { colors } = require("../../utility/colors.js");

const voteTypes = ["total", "by_mail", "in_person"];

const EarlyVoteDemographicSection = ({
  queryString,
  comparisonQueryString,
}) => {
  const dispatch = useDispatch();
  const earlyVoteVoteType = useSelector(
    (state) => state.districtPage.earlyVoteVoteType
  );
  const earlyVoteComparison = useSelector(
    (state) => state.districtPage.earlyVoteComparison
  );
  const comparisonInfo = useSelector(
    (state) => state.districtPage.comparisonInfo
  );

  const comparisonOptions = [
    comparisonInfo.name ? comparisonInfo.name : "No Comparison",
    "2024 Registered Voters",
    "2024 Likely Voters",
    "2020 Early Vote",
  ];

  const demographicCategories = [
    "party",
    "gender",
    "age",
    "partisanship",
    "ethnicity",
    "urbanicity",
  ];

  return (
    <>
      <div className="m-2 flex flex-row">
        <div className="mt-2 text-lg tracking-tight font-light mb-4">
          Show me{" "}
          <PopUpPicker
            items={voteTypes}
            selectedItem={earlyVoteVoteType}
            onItemSelect={(type) => dispatch(setEarlyVoteVoteType(type))}
            itemRenderer={(item) =>
              item.replace(/_/g, " ").replace(/\b\w/g, (l) => l.toUpperCase())
            }
          >
            <span className="font-semibold text-chartColors-primary underline underline-offset-2 decoration-black cursor-pointer">
              {earlyVoteVoteType
                .replace(/_/g, " ")
                .replace(/\b\w/g, (l) => l.toUpperCase())}
            </span>
          </PopUpPicker>{" "}
          votes cast. Compared to{" "}
          <PopUpPicker
            items={comparisonOptions}
            selectedItem={earlyVoteComparison}
            onItemSelect={(comparison) =>
              dispatch(setEarlyVoteComparison(comparison))
            }
          >
            <span
              className={`font-semibold underline underline-offset-2 decoration-black cursor-pointer ${earlyVoteComparison == "2024 Registered Voters" || earlyVoteComparison == "2024 Likely Voters" || earlyVoteComparison == "2020 Early Vote" ? "text-mviolet-300" : comparisonInfo.name ? "text-chartColors-secondary" : "text-morange-700"}`}
            >
              {earlyVoteComparison != "2020 Early Vote" &&
              earlyVoteComparison != "2024 Registered Voters" &&
              earlyVoteComparison != "2024 Likely Voters"
                ? comparisonOptions[0]
                : earlyVoteComparison}
            </span>
          </PopUpPicker>
          .
        </div>
      </div>
      <Grid smCols={1} mdCols={1} lgCols={1} gap={6}>
        {demographicCategories.map((category) => {
          const endpoint = `early_vote/counts?demographic_category=${category}&vote_type=${earlyVoteVoteType}&${queryString}`;
          let comparisonEndpoint = null;
          if (earlyVoteComparison == "2020 Early Vote") {
            comparisonEndpoint = `g2020_early_vote/counts?demographic_category=${category}&vote_type=${earlyVoteVoteType}&${queryString}`;
          } else if (earlyVoteComparison == "2024 Registered Voters") {
            comparisonEndpoint = `voterfile/demographics/${category}?${queryString}`;
          } else if (earlyVoteComparison == "2024 Likely Voters") {
            comparisonEndpoint = `voterfile/score/sum?demographic_category=${category}&${queryString}&score=presidential_general_turnout&turnout_rate=false`;
          } else {
            if (comparisonQueryString) {
              comparisonEndpoint = `early_vote/counts?demographic_category=${category}&vote_type=${earlyVoteVoteType}&${comparisonQueryString}`;
            } // Overrule state comparison if comparison selected.
          }

          return (
            <BarChartCard
              key={category}
              title={category
                .replace(/_/g, " ")
                .split(" ")
                .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
                .join(" ")}
              subtitle="Among early voters"
              endpoint={endpoint}
              comparisonEndpoint={comparisonEndpoint}
              colspan={1}
              chartProps={{
                comparisonColor:
                  earlyVoteComparison != "2020 Early Vote" &&
                  earlyVoteComparison != "2024 Registered Voters" &&
                  earlyVoteComparison != "2024 Likely Voters"
                    ? colors.chartColors["secondary"]
                    : colors.mviolet["300"],
              }}
            />
          );
        })}
      </Grid>
    </>
  );
};

export { EarlyVoteDemographicSection };
