import React from "react";
import { tableParameters } from "../../pages/search/config";

const titleCase = (str) =>
  str
    .replace(/_/g, " ")
    .toLowerCase()
    .split(" ")
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(" ");

const TableParameters = ({ parameters }) => {
  const renderFilters = (filters) => {
    if (!filters || filters.length === 0) {
      return (
        <span className="underline underline-offset-2 font-semibold text-morange-700 decoration-black">
          all voters
        </span>
      );
    }

    const filterGroups = {};
    filters.forEach((filter) => {
      let filterType = null;
      for (const [key, value] of Object.entries(tableParameters)) {
        if (value && typeof value === "object" && "filters" in value) {
          if (Array.isArray(value.filters)) {
            const matchingFilter = value.filters.find(
              (f) =>
                f.toLowerCase().replace(/\s/g, "_") === filter.toLowerCase()
            );
            if (matchingFilter) {
              filterType = key;
              break;
            }
          } else if (typeof value.filters === "object") {
            for (const [subKey, subValue] of Object.entries(value.filters)) {
              if (filter.startsWith(subKey.replace(/\s/g, "_"))) {
                filterType = key;
                break;
              }
            }
            if (filterType) break;
          }
        }
      }
      if (filterType) {
        if (!filterGroups[filterType]) {
          filterGroups[filterType] = [];
        }
        filterGroups[filterType].push(filter);
      }
    });

    return Object.entries(filterGroups).map(
      ([filterType, groupFilters], groupIndex) => (
        <React.Fragment key={filterType}>
          {groupIndex > 0 && " AND "}
          {groupFilters.length > 1 && "("}
          {groupFilters.map((filter, index) => (
            <React.Fragment key={filter}>
              {index > 0 && " OR "}
              <span className="font-semibold text-morange-700 group inline-flex items-center">
                <span className="underline underline-offset-2 decoration-black">
                  {titleCase(filter.split("_").join(" "))}
                </span>
              </span>
            </React.Fragment>
          ))}
          {groupFilters.length > 1 && ")"}
        </React.Fragment>
      )
    );
  };

  const renderParameter = (value, type) => {
    if (type === "filters") {
      return renderFilters(value);
    } else if (type === "places") {
      const placeText =
        parameters.search === "simple"
          ? `places like "${value}"`
          : value.join(" vs ");
      return (
        <span className="font-semibold text-morange-700">
          <span className="underline underline-offset-2 decoration-black">
            {placeText}
          </span>
        </span>
      );
    } else if (!value || (Array.isArray(value) && value.length === 0)) {
      return (
        <span className="underline underline-offset-2 font-semibold text-morange-700 decoration-black">
          {type === "metrics" ? "Registered Voters" : `Select ${type}`}
        </span>
      );
    }

    const renderItem = (item) => (
      <span className="font-semibold text-morange-700 group inline-flex items-center">
        <span className="underline underline-offset-2 decoration-black">
          {titleCase(item)}
        </span>
      </span>
    );

    if (Array.isArray(value)) {
      return value.map((item, index) => (
        <React.Fragment key={item}>
          {renderItem(item)}
          {index < value.length - 1 && ", "}
        </React.Fragment>
      ));
    }

    return renderItem(value);
  };

  return (
    <div className="w-full mt-8 flex flex-col container mx-auto items-start space-y-4">
      <div className="text-lg max-w-prose tracking-tight font-light">
        Show me {renderParameter(parameters.metrics, "metrics")} for{" "}
        {renderParameter(parameters.filters, "filters")} in{" "}
        {renderParameter(parameters.places, "places")}{" "}
      </div>
    </div>
  );
};

export default TableParameters;
