import React from "react";
import { Provider } from "react-redux";
import { store } from "./store";
import { BrowserRouter as Router } from "react-router-dom";
import AppRoutes from "./Routes";

function App() {
  return (
      <Provider store={store}>
        <Router>
          <AppRoutes />
        </Router>
      </Provider>
  );
}

export default App;
