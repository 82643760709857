import { createUseBackendData } from "../../hooks/data";
import { Card } from "../../components/layouts/Cards";
import {
  StatBoxesCard,
  TurnoutBreakdownCard,
} from "../../components/layouts/Cards";
import { useSelector, useDispatch } from "react-redux";
import {
  toggleTurnoutDemographic,
  toggleTurnoutScore,
} from "../../store/districtPage";
import { demographicCategories, turnoutScores } from "./config";
import { Badge } from "../../components/text/Badge";
import { PopUpPicker } from "../../components/forms/PopUpPicker";

const capitalizeFormat = (string) => {
  return `${string
    .replace(/_/g, " ")
    .split(" ")
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(" ")}`;
};

const TurnoutScoreSection = ({ queryString, comparisonQueryString }) => {
  const dispatch = useDispatch();

  const score = useSelector((state) => state.districtPage.turnoutScores.score);

  const demographic = useSelector(
    (state) => state.districtPage.turnoutScores.demographic
  );

  const handleScoreSelect = (score) => {
    dispatch(toggleTurnoutScore({ score }));
  };

  const handleDemographicSelect = (demographic) => {
    dispatch(toggleTurnoutDemographic({ demographic }));
  };

  return (
    <>
      <Card
        title={<p className="!text-3xl font-bold text-left">Turnout Scores</p>}
        id="turnout-scores"
        colspan={12}
        children={
          <>
            <div className="border-b border border-slate-900 w-full col-span-12" />{" "}
          </>
        }
        className="pt-4"
      />
      <StatBoxesCard
        id="turnout-score-toplines"
        title="Projected Turnout"
        subtitle="By election type"
        dataHook={createUseBackendData(
          `voterfile/score/sum?${queryString}&score=presidential_general_turnout&score=midterm_general_turnout&score=presidential_primary_turnout&score=non_presidential_primary_turnout`,
          true
        )}
        colspan={12}
      />
      <TurnoutBreakdownCard
        id="turnout-score-breakout"
        title="Projected Turnout"
        height={500}
        subtitle=<div>
          Projected{" "}
          <PopUpPicker
            items={turnoutScores}
            selectedItem={score}
            onItemSelect={handleScoreSelect}
            itemRenderer={(item) => capitalizeFormat(item)}
          >
            <Badge active={true} color="orange">
              <span className="tracking-normal">{capitalizeFormat(score)}</span>
            </Badge>
          </PopUpPicker>{" "}
          by{" "}
          <PopUpPicker
            items={demographicCategories}
            selectedItem={demographic}
            onItemSelect={handleDemographicSelect}
            itemRenderer={(item) => capitalizeFormat(item)}
          >
            <Badge active={true} color="blue">
              <span className="tracking-normal">
                {capitalizeFormat(demographic)}
              </span>
            </Badge>
          </PopUpPicker>
        </div>
        footnote=<div className="mt-4">
          Source: Atlas. Note that this is modeled based on past election
          patterns, and may not reflect future turnout.
        </div>
        chartProps={{
          verticalMargin: 40,
        }}
        dataHook={createUseBackendData(
          `voterfile/score/sum?${queryString}&score=${score}&demographic_category=${demographic}`,
          true
        )}
        comparisonDataHook={
          comparisonQueryString !== undefined
            ? createUseBackendData(
                `voterfile/score/sum?${comparisonQueryString}&score=${score}&demographic_category=${demographic}`,
                true
              )
            : undefined
        }
        leanDataHook={createUseBackendData(
          `voterfile/partisan_lean/sum?${queryString}&score=${score}&demographic_category=${demographic}`,
          false
        )}
        comparisonLeanDataHook={
          comparisonQueryString !== undefined
            ? createUseBackendData(
                `voterfile/partisan_lean/sum?${comparisonQueryString}&score=${score}&demographic_category=${demographic}`,
                false
              )
            : undefined
        }
        colspan={12}
      />
    </>
  );
};

export { TurnoutScoreSection };
