import { Card } from "../../components/layouts/Cards";
import { civicPulseConfig } from "../district/config";
import { createUseBackendData } from "../../hooks/data";
import { HorizontalBarChart } from "../../components/charts/HorizontalBarChart";

const CivicPulseSection = ({ queryString, comparisonQueryString }) => {
  if (queryString.includes("school")) {
    return null;
  }
  return (
    <>
      <Card
        title={<p className="!text-3xl font-bold text-left">Civic Pulse</p>}
        id="civic-pulse"
        colspan={12}
        children={
          <>
            <div className="border-b border border-slate-900 w-full col-span-12" />{" "}
          </>
        }
        className="pt-4"
        footnote="Civic Pulse results are based on survey data from ten thousand monthly interviews with adults across the country. They are not actual survey results for any specific geography, but are adjusted to demographically reflect the selected district."
      />
      {civicPulseConfig.questions.map((question) => {
        const { data, loading, error } = createUseBackendData(
          `civic_pulse/${question.id}?${queryString}`
        )();

        const { data: comparisonData } = createUseBackendData(
          `civic_pulse/${question.id}?${comparisonQueryString}`
        )();

        return (
          <Card
            key={question.id}
            title={
              <p className="!text-xl font-bold text-left">
                {question.question}
              </p>
            }
            footnote="Civic Pulse results are modeled and express the likely views of demographically and geographically similar voters, and not necessarily the actual views of these specific voters."
            id={`civic-pulse-${question.id}`}
            colspan={12}
            children={
              <div className="py-6">
                <HorizontalBarChart
                  chartData={data}
                  comparisonData={
                    comparisonQueryString !== undefined ? comparisonData : null
                  }
                  loading={loading}
                  error={error}
                  height={400}
                  isPercentage={true}
                  labelAreaWidth={120}
                />
              </div>
            }
            className="pt-4"
          />
        );
      })}
    </>
  );
};

export { CivicPulseSection };
