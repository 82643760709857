import React, { useState, useCallback } from "react";
import { Card } from "../layouts/Card";
import { ControlButtons } from "../charts/ControlButtons";
import { ChartContainer } from "../charts/ChartContainer";
import { StatBoxes } from "../charts/StatBox";
import { useRandomData } from "../../hooks/data";

const StatBoxesCard = ({
  title,
  subtitle,
  footnote = null,
  dataHook = useRandomData,
  colspan = 1,
  width = null,
  height = 150,
  outline = false,
  className = "",
  chartProps = {},
  id = null,
  children = null,
}) => {
  const { data, loading, error, toggleDataFormat, isPercentage } = dataHook();
  const [showControls, setShowControls] = useState(false);

  const handleMouseEnter = useCallback(() => setShowControls(true), []);
  const handleMouseLeave = useCallback(() => setShowControls(false), []);

  return (
    <Card
      title={title}
      subtitle={subtitle}
      footnote={footnote}
      colspan={colspan}
      outline={outline}
      className={className}
      id={id}
    >
      {children}
      <ChartContainer
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        data={data}
        isPercentage={isPercentage}
        toggleDataFormat={toggleDataFormat}
      >
        {showControls && (
          <ControlButtons
            data={data}
            isPercentage={isPercentage}
            toggleDataFormat={toggleDataFormat}
          />
        )}
        <StatBoxes
          loading={loading}
          chartData={data}
          isPercentage={isPercentage}
          width={width}
          height={height}
          labelFontSize={".75rem"}
          valueFontSize={"2.5rem"}
          {...chartProps}
        />
      </ChartContainer>
    </Card>
  );
};

export default StatBoxesCard;
