import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Grid } from "../../components/layouts/Grid";
import { Card } from "../../components/layouts/Cards";
import { Button } from "../../components/forms/Button";
import fetchRegisteredVoters from "../../utility/fetchRegisteredVoters";
import fetchDistrictName from "../../utility/fetchDistrictName";
import { MainScoreSection } from "./MainScoreSection";
import { TurnoutScoreSection } from "./TurnoutScoreSection";
import { DemographicSection } from "./DemographicSection";
import { VoteHistorySection } from "./VoteHistorySection";
import { CivicPulseSection } from "./CivicPulseSection";
import { dotStream } from "ldrs";
import { ChevronDownIcon, ChevronRightIcon } from "@heroicons/react/20/solid";
import { TabsHeader } from "./TabsHeader";
import FuzzyGeoSearch from "../../components/search/FuzzyGeoSearch";
import { EarlyVoteSection } from "./EarlyVoteSection";
import { setDistrictInfo, setComparisonInfo } from "../../store/districtPage";

dotStream.register();

const MainContent = ({ queryString, comparisonQueryString }) => {
  const dispatch = useDispatch();
  const activeTab = useSelector((state) => state.districtPage.selectedTab);
  const districtInfo = useSelector((state) => state.districtPage.districtInfo);
  const comparisonInfo = useSelector(
    (state) => state.districtPage.comparisonInfo
  );
  const [geoModalIsOpen, setGeoModalIsOpen] = useState(false);
  const [comparisonDropdownOpen, setComparisonDropdownOpen] = useState(false);
  const openGeoModal = () => setGeoModalIsOpen(true);
  const closeGeoModal = () => setGeoModalIsOpen(false);

  const updateUrlParams = (updateFn) => {
    const url = new URL(window.location.href);
    const params = new URLSearchParams(url.search);

    // Remove existing comparison parameters
    const keysToDelete = [];
    for (const key of params.keys()) {
      if (key.startsWith("comparison_")) {
        keysToDelete.push(key);
      }
    }
    keysToDelete.forEach((key) => params.delete(key));

    // Apply custom update function
    updateFn(params);

    // Update the URL
    url.search = params.toString();
    window.location.href = url.toString();
  };

  const removeComparison = () => {
    updateUrlParams(() => {});
  };

  const handleComparisonSelect = (item) => {
    const comparisonQuery = item.geo_query
      .split("&")
      .map((param) => `comparison_${param}`)
      .join("&");

    updateUrlParams((params) => {
      // Add new comparison parameters
      comparisonQuery.split("&").forEach((param) => {
        const [key, value] = param.split("=");
        params.set(key, value);
      });
    });
  };

  useEffect(() => {
    async function fetchDistrictData() {
      const numRegisteredVoters = await fetchRegisteredVoters(queryString);
      const name = await fetchDistrictName(queryString);
      dispatch(
        setDistrictInfo({ name, registeredVoters: numRegisteredVoters })
      );
    }
    fetchDistrictData();
  }, [queryString, dispatch]);

  useEffect(() => {
    async function fetchComparisonData() {
      if (comparisonQueryString) {
        const numRegisteredVoters = await fetchRegisteredVoters(
          comparisonQueryString
        );
        const name = await fetchDistrictName(comparisonQueryString);
        dispatch(
          setComparisonInfo({ name, registeredVoters: numRegisteredVoters })
        );
      } else {
        dispatch(setComparisonInfo({ name: null, registeredVoters: null }));
      }
    }
    fetchComparisonData();
  }, [comparisonQueryString, dispatch]);

  const renderTabContent = () => {
    switch (activeTab) {
      case "Demographics":
        return (
          <DemographicSection
            queryString={queryString}
            comparisonQueryString={comparisonQueryString}
          />
        );
      case "Turnout Scores":
        return (
          <TurnoutScoreSection
            queryString={queryString}
            comparisonQueryString={comparisonQueryString}
          />
        );
      case "Vote History":
        return (
          <VoteHistorySection
            queryString={queryString}
            comparisonQueryString={comparisonQueryString}
          />
        );
      case "Scores":
        return (
          <MainScoreSection
            queryString={queryString}
            comparisonQueryString={comparisonQueryString}
          />
        );
      case "Civic Pulse":
        return (
          <CivicPulseSection
            queryString={queryString}
            comparisonQueryString={comparisonQueryString}
          />
        );
      case "Early Vote":
        return (
          <EarlyVoteSection
            queryString={queryString}
            comparisonQueryString={comparisonQueryString}
          />
        );
      default:
        return null;
    }
  };

  return (
    <main className="flex-1">
      <Grid className="pb-[90vh]">
        <div className="col-span-12 p-2 flex items-center justify-between">
          <div className="flex items-center">
            <a href="/" className="text-sm text-morange-700">
              Search
            </a>
            <ChevronRightIcon className="h-5 w-5" />
            <span className="text-sm font-medium">
              {districtInfo.name || (
                <l-dot-stream
                  size="100"
                  speed="2.5"
                  color="black"
                ></l-dot-stream>
              )}
            </span>
          </div>
          <div className="relative">
            <Button
              onClick={() => {
                if (comparisonQueryString) {
                  setComparisonDropdownOpen(!comparisonDropdownOpen);
                } else {
                  openGeoModal();
                }
              }}
              className="bg-morange-700 hover:bg-morange-600 text-white rounded flex items-center !mb-0"
            >
              Compare
              {comparisonQueryString ? (
                <ChevronDownIcon className="h-5 w-5 ml-2" />
              ) : null}
            </Button>
            {comparisonQueryString && comparisonDropdownOpen && (
              <div className="absolute right-0 mt-1 w-full bg-white border border-gray-300 rounded-md shadow-lg z-[100]">
                <ul className="divide-y divide-gray-200">
                  <li
                    className="px-4 py-2 cursor-pointer hover:bg-style-bg text-sm"
                    onClick={() => {
                      openGeoModal();
                      setComparisonDropdownOpen(false);
                    }}
                  >
                    Change
                  </li>
                  <li
                    className="px-4 py-2 cursor-pointer hover:bg-style-bg text-sm"
                    onClick={() => {
                      removeComparison();
                      setComparisonDropdownOpen(false);
                    }}
                  >
                    Remove
                  </li>
                </ul>
              </div>
            )}
          </div>
        </div>
        <div className="sticky top-0 z-10 bg-style-bg col-span-12">
          <Card className="" colspan={12} id="overview">
            <div className="flex items-center pb-2 justify-between">
              <div className="flex flex-col items-start">
                <p className="!text-4xl font-bold text-left underline decoration-solid decoration-8 decoration-chartColors-primary">
                  {districtInfo.name || (
                    <l-dot-stream
                      size="100"
                      speed="2.5"
                      color="black"
                    ></l-dot-stream>
                  )}
                </p>
                {districtInfo.registeredVoters ? (
                  <div className="font-inter text-sm text-gray-500 pt-1">
                    <p>{districtInfo.registeredVoters} registered voters</p>
                  </div>
                ) : (
                  <l-dot-stream
                    size="60"
                    speed="2.5"
                    color="grey"
                  ></l-dot-stream>
                )}
              </div>
              {comparisonQueryString !== undefined ? (
                <>
                  <span className="text-2xl font-medium px-2">vs</span>
                  <div className="flex flex-col items-end">
                    <p className="!text-4xl font-bold text-right underline decoration-solid decoration-8 decoration-chartColors-secondary">
                      {comparisonInfo.name || (
                        <l-dot-stream
                          size="100"
                          speed="2.5"
                          color="black"
                        ></l-dot-stream>
                      )}
                    </p>
                    {comparisonInfo.registeredVoters ? (
                      <div className="font-inter text-sm text-gray-500 pt-1">
                        <p>
                          {comparisonInfo.registeredVoters} registered voters
                        </p>
                      </div>
                    ) : (
                      <l-dot-stream
                        size="60"
                        speed="2.5"
                        color="grey"
                      ></l-dot-stream>
                    )}
                  </div>
                </>
              ) : null}
            </div>
          </Card>
          <TabsHeader />
        </div>
        <div className="tab-content transition-opacity duration-500 ease-in-out col-span-12">
          {renderTabContent()}
        </div>
      </Grid>
      {geoModalIsOpen && (
        <div
          className="fixed inset-0 z-50 flex items-start justify-center bg-black bg-opacity-50"
          style={{ paddingTop: "10vh" }}
        >
          <div className="bg-style-bg rounded-lg shadow-lg max-w-3xl min-w-[85vh] max-h-[85vh] p-6">
            <div className="flex justify-between items-center mb-6">
              <h2 className="text-2xl font-semibold">
                Select a comparison district
              </h2>
              <button
                className="text-gray-400 hover:text-gray-600"
                onClick={closeGeoModal}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-6 w-6"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M6 18L18 6M6 6l12 12"
                  />
                </svg>
              </button>
            </div>
            <FuzzyGeoSearch handleSelect={handleComparisonSelect} />
          </div>
        </div>
      )}
    </main>
  );
};

export { MainContent };
