import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import {
  fetchResults,
  resetParameters,
  clearError,
} from "../../store/searchPage";
import { Table } from "../../components/tables/Table";
import TableParameters from "../../components/tables/TableParameters";
import SearchBar from "../../components/search/SearchBar";
import { newtonsCradle } from "ldrs";

newtonsCradle.register();

const SearchResults = ({ queryString = null }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = new URLSearchParams(useLocation().search).get("query");
  const query = queryString || location;
  const isFromDataCatalog = new URLSearchParams(useLocation().search).get(
    "fromDataCatalog"
  );
  const { results, loading, parameters, error } = useSelector(
    (state) => state.search
  );

  useEffect(() => {
    if (query && !isFromDataCatalog) {
      dispatch(fetchResults(query));
    }

    return () => {
      dispatch(clearError()); // Clear error when component unmounts
    };
  }, [query, dispatch, isFromDataCatalog]);

  useEffect(() => {
    if (error) {
      dispatch(resetParameters());
      navigate("/error", {
        state: {
          message: error,
        },
      });
    }
  }, [error, navigate, dispatch]);

  if (loading) {
    return (
      <div className="w-screen h-screen flex items-center justify-center bg-style-bg">
        <l-newtons-cradle
          size="120"
          speed="1.4"
          color="black"
        ></l-newtons-cradle>
      </div>
    );
  }

  return (
    <div className="flex flex-col items-center justify-center min-h-screen bg-style-bg">
      <div className="w-[600px] h-[64px] max-w-xl mt-8">
        <SearchBar useCarousel={false}></SearchBar>
      </div>
      <hr className="w-full max-w-2xl mt-6 border-t border-gray-300" />
      {results.length > 0 ? (
        <>
          <div className="min-w-[64rem]">
            {parameters && <TableParameters parameters={parameters} />}
            <Table
              data={results}
              showExportButton={true}
              columnConfig={[
                {
                  key: "name",
                  type: "link",
                  hrefSource: "geo_query",
                  hrefModifier: (geo_query) => `/district-profile?${geo_query}`,
                },
              ]}
            />
          </div>
        </>
      ) : (
        <p className="text-gray-500">No results found for "{query}"</p>
      )}
    </div>
  );
};

export default SearchResults;
