// ReusableCombobox.jsx
import { useState } from "react";
import { ChevronDownIcon, CheckIcon } from "@heroicons/react/20/solid";
import {
  Combobox,
  ComboboxButton,
  ComboboxInput,
  ComboboxOption,
  ComboboxOptions,
} from "@headlessui/react";

const ReusableCombobox = ({
  items,
  selected,
  onSelect,
  displayValue = (item) => item,
  formatOption = (item) => item,
  className = "",
  placeholder = "",
}) => {
  const [query, setQuery] = useState("");

  const filteredItems =
    query === ""
      ? items
      : items.filter((item) =>
          formatOption(item).toLowerCase().includes(query.toLowerCase())
        );

  return (
    <Combobox value={selected} onChange={onSelect} onClose={() => setQuery("")}>
      <div className={`relative font-inter w-full ${className}`}>
        <ComboboxInput
          className="font-inter text-style-text-dark shadow-md text-sm rounded-lg w-full bg-style-surface border-none py-1.5 pr-8 pl-3 focus:outline-none data-[focus]:outline-2 data-[focus]:-outline-offset-2 data-[focus]:outline-morange-200 h-10"
          displayValue={displayValue}
          onChange={(event) => setQuery(event.target.value)}
          placeholder={placeholder}
        />
        <ComboboxButton className="group absolute inset-y-0 right-0 px-2.5">
          <ChevronDownIcon className="size-4 fill-slate-700 group-hover:text-slate-900" />
        </ComboboxButton>
        <ComboboxOptions
          anchor="bottom"
          transition
          className="absolute z-50 w-[var(--input-width)] max-h-48 overflow-auto rounded-xl border border-morange-100/5 bg-style-surface p-1 [--anchor-gap:var(--spacing-1)] empty:invisible transition duration-100 ease-in data-[leave]:data-[closed]:opacity-0"
        >
          {filteredItems.map((item) => (
            <ComboboxOption
              key={item}
              value={item}
              className="group flex cursor-default gap-2 rounded-lg py-1.5 px-3 select-none data-[focus]:bg-morange-200 items-start"
            >
              <CheckIcon className="invisible size-4 fill-slate-700 group-data-[selected]:visible font-inter " />
              {formatOption(item)}
            </ComboboxOption>
          ))}
        </ComboboxOptions>
      </div>
    </Combobox>
  );
};

export { ReusableCombobox };
